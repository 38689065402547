import { RouterLink } from '@angular/router'

export const Environment = {
  API_PATH: '/api/v1/',
  TITLE: 'Fluidilogix',
  LOGO: '/assets/logo.png',
  LAYOUT_MENU: [
    {
      separator: true,
    },
    {
      label: 'DÉPÔTS',
      items: [{ label: 'Consulter les dépôts', routerLink: 'depots' }],
    },
    {
      label: 'STOCKS',
      items: [
        { label: 'Stocks des clients', routerLink: 'stocks/clients' },
        { label: 'Stocks des transporteurs', routerLink: 'stocks/transporteurs' },
      ],
    },
    {
      label: 'OPÉRATIONS',
      items: [
        { label: 'Reclassement des produits', routerLink: 'operations/reclassement' },
        { label: 'Remplissage des fûts', routerLink: 'operations/remplissage' },
        { label: 'Purge des fûts', routerLink: 'operations/purge' },
        { label: 'Reformage des fûts', routerLink: 'operations/reformage' },
        { label: 'Recyclage des fûts', routerLink: 'operations/recyclage' },
      ],
    },
    {
      label: 'MOUVEMENTS DE PRODUITS',
      items: [{ label: 'Entrées des emballés', routerLink: 'mouvements-produits/emballes' }],
    },
    {
      label: 'INVENTAIRES',
      items: [{ label: 'Inventaires des dépôts', routerLink: 'inventaires/listes' }],
    },
    {
      label: 'CODES-BARRES',
      items: [
        { label: 'Génération de codes-barres', routerLink: 'codes-barres/lots' },
        { label: 'Produits', routerLink: 'codes-barres/produits' },
      ],
    },
    {
      label: 'COMMANDES',
      items: [{ label: 'Ordres', routerLink: 'commandes/ordres' }],
    },
    {
      label: 'APPLICATION MOBILE',
      items: [{ label: 'Télécharger l\'application', routerLink: 'apks' }],
    },
  ],
  LAYOUT_FOOTER_MENU: [
    {
      label: 'Utilisateurs',
      routerLink: 'utilisateurs',
      icon: 'pi pi-users',
    },
    {
      label: 'Référentiels',
      routerLink: 'referentiels',
      icon: 'pi pi-sliders-h',
    },
  ],
}
